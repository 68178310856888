import LayoutA1 from "../../components/layout-a1"
import React from "react"
import './achievement.css'


export default class Achievement extends React.Component {
  componentDidMount() {
    let width=document.body.clientWidth
    console.log(width)
    this.setState({width})
  }
  render() {
    return(
      <LayoutA1 location={this.props.location} >

        <div className="achievement">
          <div className="scan-code-title">扫码查看</div>
          <div className="code"><img alt="hello" src={require("./images/race_code.png")}></img></div>
          <div className="scan-code-desc">打开微信扫一扫</div>
          <div  className="to-wx">打开微信查看成绩</div>
          <div className="ranking-container">
            {/*<div className="ranking-icon"><img  alt="hello" src={require(this.state.width<480?'../images/ranking-phone.png':"../images/rankings.png")}/></div>*/}
            <div className="achievement-ranking">
              {/*{ranking}*/}
              <img alt="hello" src={require('./images/ranking.png')}/>
            </div>
          </div>
        </div>
      </LayoutA1>
    )
  }
}